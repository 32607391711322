<template>
  <div class="ic-cornerstone">
    <wl-cornerstone-banner
      title="Free People Search Engine"
      sub-text="Find Phone Numbers, Email Addresses, Social Media Profiles, Contact Info and More!"
      :banner-image="bannerImage"
      :prefill-fields="$parent.$attrs['prefill-fields']"
      :fallback-fields="cms.cornerstone.banner.fallback_fields"
      :search-tabs="cms.cornerstone.banner.search_tabs"
      :button-icon="cms.cornerstone.banner.button_icon"
      :search-icon-size="cms.cornerstone.banner.button_icon_size"
      :review-text="cms.cornerstone.banner.review_text"
      :secured-text="cms.cornerstone.banner.secured_text"
      :rating-image="ratingImage"
      :people-url="peopleSearchLink"
      :no-query-string="true"
      initial-search-type="people"
      landing-id="3lv1s"
      :default-state-option-label="
        cms.cornerstone.banner.default_state_option_label
      "
    />
    <div class="ic-cornerstone__section ic-cornerstone__section--color">
      <div class="ic-cornerstone__container">
        <h2>What Your Information.com People Search Will Discover</h2>
        <p>
          Dig deep and discover everything you need to know when you search for
          someone with Information.com. When you conduct an advanced people
          search with us, we'll provide you with a single, easy-to-read report
          that includes:
        </p>
        <ul class="ic-cornerstone__list-row">
          <li>Full name and any previous names</li>
          <li>Relatives</li>
          <li>Marital status</li>
          <li>Age and date of birth</li>
          <li>Social media accounts</li>
          <li>Court records</li>
          <li>Educational background</li>
          <li>Current and past addresses</li>
          <li>Traffic Violations</li>
          <li>Online dating accounts</li>
          <li>Criminal records</li>
          <li>Sexual Offender Status</li>
        </ul>
      </div>
    </div>
    <div class="ic-cornerstone__section ic-cornerstone__section--how">
      <div class="ic-cornerstone__container">
        <div class="ic-cornerstone__columns">
          <h2>How to Use Our People Search Tool in 5 Easy Steps</h2>
          <p>
            Finding someone is simple. All you need is a person's first and last
            name.
          </p>
          <h3>Step 1</h3>
          <p>
            Simply enter the person's name into the search tool and click
            'Search'. As we pull information from our database, we may ask you
            some additional questions to narrow down your search options.
          </p>
          <h3>Step 2</h3>
          <p>
            Respond to any additional questions you're asked about, and include
            details, like age and location, if you have it available. Then, wait
            for us to match you with the appropriate person and build the
            report.
          </p>
          <h3>Step 3</h3>
          <p>
            Agree to view the information in the report about the person you're
            searching for. You may have to agree to see "Embarrassing"
            information about them and agree to the general privacy policy.
          </p>
          <h3>Step 4</h3>
          <p>
            Wait for the progress bar to complete the report, and then provide
            some information about yourself to have the report saved. You'll be
            asked for your full name and email address. Don't worry, your
            information is kept private, and nobody will know about your search.
          </p>
          <h3>Step 5</h3>
          <p>
            Wait for our database to compile the information about the
            individual you're looking up into an easy-to-read report. Reports
            may include images and other personal information.
          </p>
        </div>
        <div class="ic-cornerstone__columns">
          <img :src="toolImage" alt="Hands looking up information on a phone" />
        </div>
      </div>
    </div>
    <div class="ic-cornerstone__section ic-cornerstone__section--why">
      <img
        :src="yellowCheck"
        class="ic-cornerstone__section-yellow-check"
        alt=""
      />
      <div class="ic-cornerstone__container">
        <h2>Why Should I Do a People Search?</h2>
        <p>
          In this day and age, it's challenging to know if someone is who they
          say they are, especially when you connect with people online. So, it's
          important to know who you're talking to.
        </p>
        <p>
          Aside from verifying someone's identity, it's also possible to connect
          with relatives, find past lovers, or check if your spouse is cheating
          on you.
        </p>
        <p>
          <strong>People often conduct a people search to:</strong>
        </p>
        <div class="ic-cornerstone__columns">
          <ul>
            <li>
              <h3>Find a Lost Friend</h3>
              <p>
                It's easy to lose track of friends over the years, especially if
                either of you have moved to another state. Whether you want to
                reconnect with old friends or just see what they're up to now, a
                people search makes it easy; you can track down your high school
                friends or college roommate in seconds.
              </p>
            </li>
            <li>
              <h3>Locate a Family Member</h3>
              <p>
                Losing touch with family members is more common than you might
                think. Our people search tool can help you reconnect with
                long-lost relatives, even if their name has changed or they've
                relocated across the country.
              </p>
              <p>
                Conducting an online people search can also help you learn more
                about your distant relatives.
              </p>
            </li>
            <li>
              <h3>Discover Previously Unknown Relatives</h3>
              <p>
                A people search can help you uncover relatives you didn't know
                existed. This can be especially helpful if you're trying to
                build a family tree.
              </p>
              <p>
                If you're trying to trace your blood relatives, a people search
                might be able to give you the answers you need. All you need to
                get started is one relative's first and second name.
              </p>
            </li>
            <li>
              <h3>Vet Romantic Partners</h3>
              <p>
                If you're contemplating a romantic relationship with someone,
                doing a people search can save you a lot of heartache. A people
                search will tell you whether your date is married, older or
                younger than they say, has a criminal record, or if they are on
                the sexual offender registry before the relationship gets
                serious.
              </p>
              <p>
                It's not uncommon for someone to fall for someone claiming to be
                something they're not. But with the help of Information.com’s
                people search tool, you can feel confident before you fully
                commit.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Screen a New Friend</h3>
              <p>
                It’s not just romantic partners you should vet. If you’re
                becoming friends with someone outside of your social circle, you
                may want to do a people search to get a better idea of who they
                are.
              </p>
              <p>
                Information you get from people searches allows you to make
                informed decisions about anyone you let into your life.
              </p>
            </li>
            <li>
              <h3>Check if Someone Is Who They Say They Are</h3>
              <p>
                Knowing exactly who you're dealing with is vital before
                exchanging money or personal information. Whether buying
                something from an online seller or checking if a job offer is
                too good to be true, our people search can help.
              </p>
              <p>
                Nearly everyone knows someone who's been catfished these days,
                but with a people search, you can perform a quick background
                check to find out if someone's online profile is authentic and
                accurate.
              </p>
            </li>
            <li>
              <h3>Investigate New Neighbors</h3>
              <p>
                If you're concerned about the new people who have moved in next
                door, a people search can give you some of that much-needed
                peace of mind. Knowing your neighbors is especially important if
                you have children or animals that spend time outside. Even if
                you live alone, it's always good to know if you can trust your
                neighbors.
              </p>
            </li>
            <li>
              <h3>Find Information About Yourself</h3>
              <p>
                Doing a people search for yourself is an excellent way to find
                out what information is out there about you. You'll be surprised
                at what information is publicly available. Knowing what
                information about you is out there can help you stay one step
                ahead and enable you to take steps to remove any sensitive
                information you don't want public.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ic-cornerstone__section ic-cornerstone__section--where">
      <div class="ic-cornerstone__container">
        <h2>Where Does Your Information Come From?</h2>
        <p>
          Our search tool compiles publicly available information from a range
          of public records, including databases from across the United States.
          Learn about where we source our information below.
        </p>
        <div class="ic-cornerstone__columns">
          <ul>
            <li>
              <h3>Criminal Records</h3>
              <p>
                Know if the person you're searching for has a criminal past. Our
                criminal record search will reveal any convictions or charges
                and any pending court cases. To compile criminal records, we
                check information from local police, superior courts, county
                sheriffs, and even district courts.
              </p>
            </li>
            <li>
              <h3>Arrest Reports</h3>
              <p>
                Learn if the person in question has ever been arrested. Arrests
                that don't lead to a conviction won't appear on a criminal
                record but will appear in our arrest report search. Information
                we check for arrest reports includes violations, jail
                sentencing, and misdemeanors.
              </p>
            </li>
            <li>
              <h3>Bankruptcies and Liens</h3>
              <p>
                Get an insight into someone's financial history. Searching for
                bankruptcies and liens will reveal if someone has a history of
                financial difficulty. While credit agencies like Experian and
                Credit Karma do a good job, we can help you dig deeper into your
                personal financial history.
              </p>
            </li>
            <li>
              <h3>Marriage &amp; Divorce Records</h3>
              <p>
                Check if someone is currently married or was married before.
                Also, learn about who they are or were married to, and if it
                ended in divorce.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Sex Offender Registries</h3>
              <p>
                Know if a person is a registered sex offender. Information about
                their location and details about the incident may also be
                available, based on police reports and court records.
              </p>
            </li>
            <li>
              <h3>Civil Records</h3>
              <p>
                Discover any non-criminal legal disputes. Civil records can
                contain a wealth of information about restraining orders, child
                custody disputes, debts, and other non-criminal legal dealings.
              </p>
              <p>
                Find out if someone's a safe driver. We search traffic
                violations to uncover involvement in road accidents, as well as
                any traffic violations, such as speeding tickets and DUIs.
              </p>
            </li>
            <li>
              <h3>Lawsuits</h3>
              <p>
                Learn if someone has been involved in any lawsuits. Not all
                lawsuits are public records, but many are, including small
                claims cases, evictions, and foreclosures.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ic-cornerstone__section ic-cornerstone__section--looking">
      <div class="ic-cornerstone__container">
        <h2>Looking For Other Ways to Search?</h2>
        <p>
          Our people search tool is one of the best ways to find information
          about someone online. However, it's not the only way to search. There
          are plenty of methods you can use to view someone's public records,
          and you can learn more about each type below.
        </p>
        <div class="ic-cornerstone__columns">
          <ul>
            <li>
              <h3>Phone Lookup</h3>
              <p>
                Incoming calls from spoofed numbers or spammers are a hassle,
                especially when you can't make them stop. Well, with
                Information.com, you have the power to stop spam and robocallers
                dead in their tracks with our
                <a href="/reverse-phone-lookup/">phone lookup tool</a>.
              </p>
              <p>
                All you have to do is enter their phone number into the search
                tool and follow the instructions to learn all of the details
                about a phone number.
              </p>
            </li>
            <li>
              <h3>Address Lookup</h3>
              <p>
                Another tool you can leverage with Information.com is an
                <a href="/reverse-address-lookup/">address lookup tool</a>. The
                address lookup tool helps you learn about a property. Discover
                who lives there, who used to live there, and when the property
                changed hands. Information about property taxes and property
                value may also be available.
              </p>
            </li>
            <li>
              <h3>Public Records</h3>
              <p>
                Want to learn everything you can about someone's public records?
                Try our
                <a href="/public-records-search/">public records search tool</a
                >. We'll help you create a report that compiles all of the
                available public records about someone. Gone are the days when
                you had to search through dozens of databases to find someone's
                information.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Criminal Records</h3>
              <p>
                With our criminal records search tool, you can discover
                someone's criminal history. Criminal history is one of the most
                important things to learn if you want to know more about
                someone. Also, you can use our
                <a href="/criminal-records-check/"
                  >criminal records search tool</a
                >
                on yourself to check your record. Doing so will help you prepare
                for a job interview, since you need to be prepared to explain
                anything on your record.
              </p>
            </li>
            <li>
              <h3>Background Check</h3>
              <p>
                Last but not least, Information.com offers a
                <a href="/background-checks-search/">background check tool</a>.
                Our background check tool is perfect for learning about
                someone's identity. It pulls records from thousands of public
                records databases to confirm someone's identity, verify their
                work history, and much more.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="ic-cornerstone__section ic-cornerstone__section--color ic-cornerstone__section--testimonials"
    >
      <div class="ic-cornerstone__container">
        <h2>
          Thousands of people like you have been satisfied with the results!
        </h2>
        <ul class="ic-cornerstone__testimonials">
          <li>
            <p>
              This website was extremely helpful in digging up people search
              records. I was looking for address and phone numbers for a guest
              list.
            </p>
            <p><strong>Jane M.</strong></p>
          </li>
          <li>
            <p>
              Information.com people search service helped me find address
              history, phone numbers and emails for my high school reunion.
            </p>
            <p><strong>Mia D.</strong></p>
          </li>
        </ul>
        <a
          href="#"
          class="ic-cornerstone__section-button"
          v-touch:start.prevent="scrollUp"
        >
          Start your Search Now
        </a>
      </div>
    </div>
    <div
      class="ic-cornerstone__section ic-cornerstone__section--people-directory"
    >
      <div class="ic-cornerstone__container">
        <h2>Information.com People Directory</h2>
        <p>
          Browse the free people search list below to find colleagues,
          classmates, friends, neighbors and relatives. Also view our trending
          names and top names list!
        </p>
        <ic-people-directory
          v-for="cardList in renderPeopleDirectory()"
          key="directory"
          :card-list="cardList"
        />
      </div>
    </div>
    <div class="ic-cornerstone__section ic-cornerstone__section--search">
      <div class="ic-cornerstone__container">
        <h2>
          Safely and efficiently search the details of anyone in the United
          States in seconds!
        </h2>
        <p>
          Information.com scours billions of public records for accurate and
          up-to-date data about the people you need to find.
        </p>
        <a
          href="#"
          class="ic-cornerstone__section-button"
          v-touch:start.prevent="scrollUp"
        >
          Start your Search Now
        </a>
      </div>
    </div>
    <div class="ic-cornerstone__section ic-cornerstone__section--faq">
      <div class="ic-cornerstone__container">
        <h2>Frequently Asked Questions</h2>
        <p>
          If you're thinking about doing a people search, you might have some
          questions. These are the most frequently asked questions about our
          people search:
        </p>
        <tz-qna
          :items="faqItems"
          :has-shuffle="false"
          :has-markdown="false"
          :is-accordion="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import WlCornerstoneBanner from '@/components/shared/wl-cornerstone-banner';
import TzQna from '@trazi/tz-qna/src/tz-qna.vue';
import IcPeopleDirectory from '@/sections/ic-people-directory';
// Images
import bannerImage from '@/assets/images/cornerstone/home.webp';
import toolImage from '@/assets/images/cornerstone/hands-phone.webp';
import yellowCheck from '@/assets/images/yellow-check.svg';
import ratingImage from '@/assets/images/shared/rating.svg';
// Helpers
import Meta from '@/assets/js/shared/misc/meta.mjs';
import scrollToElement from '@/assets/js/shared/helpers/scrollToElement';
import getPeopleDirectoryData from '@/assets/js/shared/helpers/getPeopleDirectoryData';
import { Exception } from 'sass';
export default {
  name: 'home',
  components: {
    WlCornerstoneBanner,
    IcPeopleDirectory,
    TzQna
  },
  data() {
    return {
      bannerImage,
      toolImage,
      yellowCheck,
      ratingImage
    };
  },
  props: {
    cms: {
      type: Object,
      default: () => {
        return {
          page: require(`@/assets/cms/pages/home.json`),
          cornerstone: require(`@/assets/cms/pages/cornerstone.json`),
          global: require(`@/assets/cms/global.json`)
        };
      }
    }
  },
  computed: {
    faqItems() {
      return this.cms.page.faq_items.map(item => ({
        questions: [{ question: item.question }],
        answers: [{ singular_value: item.answer }]
      }));
    },
    peopleSearchLink() {
      const { query } = this.$route;
      const link = this.cms.cornerstone.banner.people_url;
      const replaceLanding = '23um01';
      const redirectLanding = '3lv1s';

      if (query.utm_source === 'google') {
        return link.replace(replaceLanding, redirectLanding);
      }

      return link;
    }
  },
  methods: {
    renderPeopleDirectory() {
      return this.people && this.people.length > 0 ? [this.people] : [];
    },
    /**
     * Scrolls user back to the top of the page
     */
    scrollUp() {
      scrollToElement('__layout', 0);
    }
  },
  async asyncData({ $bugsnag }) {
    if (!process.server) {
      return;
    }

    return getPeopleDirectoryData($bugsnag);
  },
  head() {
    return {
      title: this.cms.page.page_title,
      meta: this.cms.page.meta ? Meta.convertToNuxtMeta(this.cms.page.meta) : []
    };
  }
};
</script>
