/**
 * Custom scrollTo with a smooth behavior that is supported on chrome, firefox and safari
 *
 * @param {string} element
 * @param {number} offset
 */
const scrollToElement = (element, offset) => {
  const startingY = window.pageYOffset;
  const elementY =
    window.pageYOffset +
    document.getElementById(element).getBoundingClientRect().top;
  // If element is close to page's bottom then window will scroll only to some position above the element.
  const targetY =
    document.body.scrollHeight - elementY < window.innerHeight
      ? document.body.scrollHeight - window.innerHeight
      : elementY;
  const diff = targetY - offset - startingY;
  // Easing function: easeInQuad
  const easing = function(t) {
    return t * t;
  };
  let start;

  if (!diff) return;

  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp;
    // Elapsed milliseconds since start of scrolling.
    const time = timestamp - start;
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / 1000, 1);
    // Apply the easing
    percent = easing(percent);

    window.scrollTo(0, startingY + diff * percent);

    if (time < 1000) {
      window.requestAnimationFrame(step);
    }
  });
};

export default scrollToElement;
