import sdk from '@trazi/seo/sdk/node';

// Note: Fix domain variable
const domain =
  process.env.ENVIRONMENT === 'production'
    ? `${process.env.website}.com`
    : process.env.TESTING_ACCOUNT_DOMAIN || 'traziventures.com';

const seoSDK = sdk(process.env.SEO_SDK_SERVER_URL, {
  headers: { tzsearch_domain: domain }
});

export default seoSDK;
