<template>
  <section class="ic-people-directory">
    <div class="ic-people-directory__cards">
      <article class="ic-people-directory__card" v-for="card in cardList">
        <h3 class="ic-people-directory__card-title">
          <a
            class="ic-people-directory__card-link"
            :href="
              formatIndexLink({
                letter: card.letter,
                primaryKey: card.primaryKey
              })
            "
            v-text="card.range.replace('|', ' ')"
          />
        </h3>
        <p
          class="ic-people-directory__card-text"
          v-text="`Includes ${card.data.join(', ')}`"
        />
      </article>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ic-people-directory',
  methods: {
    /**
     *
     * @param {object} opts
     * @param {string} opts.letter
     * @param {string} opts.primaryKey
     * @returns {string}
     */
    formatIndexLink(opts = {}) {
      const letter = opts.letter.toLowerCase();
      const tier2 = opts.primaryKey;

      return `/names/${letter}/${tier2}/`;
    }
  },
  props: {
    cardList: {
      type: Array
    }
  }
};
</script>
