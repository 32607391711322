import seoSDK from '@/assets/js/shared/services/seo-sdk';
const { NameRanges } = seoSDK;
import queryWithSearchSDK from '@/assets/js/shared/helpers/queryWithSearchSDK';

/**
 * Get people directory data
 * @param {object} $bugsnag
 * @returns
 */
const getPeopleDirectoryData = async ($bugsnag = {}) => {
  const payload = {};

  try {
    const response = await queryWithSearchSDK({
      queryParams: {},
      searchMethod: NameRanges.byPeople,
      defaultReturnValue: []
    });

    payload.people = response.data;
  } catch (err) {
    payload.people = [];
    console.error(e);
    $bugsnag?.notify(err);
  }

  return payload;
};

export default getPeopleDirectoryData;
